<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }} Subject</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="subject.title"
                    :error="$v.subject.title.$error"
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.subject.title.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="subject.grade_id"
                    :items="grades"
                    item-text="title"
                    item-value="id"
                    hide-details
                    :error="$v.subject.grade_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Grade <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.subject.grade_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12">
                  Status
                  <v-switch
                    v-model="subject.is_active"
                    :label="subject.is_active ? 'Yes' : 'No'"
                    outlined
                    dense
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import GradeService from "@/service/grade/GradeService";
import SubjectService from "@/service/subject/SubjectService";

const subject = new SubjectService();
const grade = new GradeService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      grades: [],
      errors: [],
      subject: {
        title: "",
        grade_id: "",
        is_active: true
      }
    };
  },
  validations: {
    subject: {
      title: { required },
      grade_id: { required }
    }
  },
  mounted() {
    this.getAllGrades();
  },
  computed: {
    title() {
      if (this.edit) {
        return "Edit";
      } else {
        return "Create";
      }
    }
  },
  methods: {
    getAllGrades() {
      grade
        .all()
        .then(response => {
          this.grades = response.data.data;
        })
        .catch(() => {});
    },
    openDialog() {
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editDialog(subject) {
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.subject = subject;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.$reset();
      this.subject = {
        title: "",
        grade_id: "",
        is_active: true
      };
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 6000);
      } else {
        if (this.edit) {
          subject
            .update(this.subject.id, this.subject)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Subject updated successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        } else {
          subject
            .store(this.subject)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Subject created successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        }
      }
    }
  }
};
</script>
